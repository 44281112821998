
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en.json';
import ar from './locales/ar.json';

const selectedLanguage = localStorage.getItem('selectedLanguage') || 'en';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en,
      },
      ar: {
        translation: ar,
      },
    },
    lng: selectedLanguage,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

  i18n.on('languageChanged', (lng) => {
    if (lng === 'ar') {
      document.body.classList.add('arabic-font');
    } else {
      document.body.classList.remove('arabic-font');
    }
    // Add this line to update the font family based on language selection
    document.body.style.fontFamily = lng === 'ar' ? 'Almarai, sans-serif' : 'Poppins, sans-serif';
  });

export default i18n;

// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
// import en from './locales/en.json';
// import ar from './locales/ar.json';

// const selectedLanguage = localStorage.getItem('selectedLanguage') || 'en';

// i18n
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     resources: {
//       en: {
//         translation: en,
//       },
//       ar: {
//         translation: ar,
//       },
//     },
//     lng: selectedLanguage,
//     fallbackLng: 'en',
//     interpolation: {
//       escapeValue: false,
//     },
//   });

// i18n.on('languageChanged', (lng) => {
//   if (lng === 'ar') {
//     document.body.classList.add('arabic-font');
//   } else {
//     document.body.classList.remove('arabic-font');
//   }
// });

// export default i18n;
