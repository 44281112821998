

// // import React, { useEffect, useState } from 'react';
// // import styles from './PostDetail.module.css';
// // import axios from 'axios';
// // import { useParams, useLocation, useNavigate } from 'react-router-dom';

// // import { useTheme } from '../../themes/ThemesContext';
// // import { MDBContainer, MDBRow, MDBCol, MDBCardImage, MDBBadge } from 'mdb-react-ui-kit';
// // import { useTranslation } from 'react-i18next';
// // import DOMPurify from 'dompurify'; 

// // const PostDetail = () => {
// //   const { t, i18n } = useTranslation();
// //   const { theme } = useTheme();
// //   const { slug } = useParams();
// //   const location = useLocation();
// //   const navigate = useNavigate(); 

// //   const [post, setPost] = useState(null);
// //   const [categories, setCategories] = useState([]);
// //   const [files, setFiles] = useState([]);
// //   const [relatedPosts, setRelatedPosts] = useState([]);
// //   const [postTags, setPostTags] = useState([]);
  
// //   useEffect(() => {
// //     window.scrollTo(0, 0);
// //   }, []);

// //   useEffect(() => {
// //     fetchPostDetail();
// //     fetchCategories();
// //   }, [slug]);

// //   useEffect(() => {
// //     if (post) {
// //       fetchPostTags(post.id);
// //     }
// //   }, [post]);

// //   useEffect(() => {
// //     if (postTags.length > 0) {
// //       fetchRelatedPosts(postTags);
// //     }
// //   }, [postTags]);

// //   const fetchPostDetail = async () => {
// //     try {
// //       const response = await axios.get(`https://admin.riderscars.com/items/posts?filter[slug][_eq]=${slug}`);
// //       const postData = response.data.data[0];
// //       const postFiles = await fetchPostFiles(postData.id);
// //       setPost(postData);
// //       setFiles(postFiles);

// //     } catch (error) {
// //       console.error('Error fetching post detail:', error);
// //     }
// //   };

// //   const fetchPostFiles = async (postId) => {
// //     try {
// //       const response = await axios.get(`https://admin.riderscars.com/items/posts_files?filter[posts_id][_eq]=${postId}`);
// //       return response.data.data.map(file => ({
// //         id: file.id,
// //         directus_files_id: file.directus_files_id
// //       }));
// //     } catch (error) {
// //       console.error('Error fetching post files:', error);
// //       return [];
// //     }
// //   };

// //   const fetchCategories = async () => {
// //     try {
// //       const response = await axios.get('https://admin.riderscars.com/items/Post_category');
// //       const categoriesData = response.data.data;

// //       // Fetch translations for categories
// //       const translationsResponse = await axios.get('https://admin.riderscars.com/items/Post_category_translations');
// //       const translationsData = translationsResponse.data.data;

// //       // Map translations to categories based on Post_category_id
// //       const categoriesWithTranslations = categoriesData.map(category => {
// //         const translation = translationsData.find(tr => tr.Post_category_id === category.id);
// //         if (translation) {
// //           return { ...category, translatedTitle: translation.title };
// //         } else {
// //           return { ...category, translatedTitle: category.title }; // Fallback to original title if no translation found
// //         }
// //       });

// //       setCategories(categoriesWithTranslations);
// //     } catch (error) {
// //       console.error('Error fetching categories:', error);
// //     }
// //   };

// //   const fetchPostTags = async (postId) => {
// //     try {
// //       const response = await axios.get(`https://admin.riderscars.com/items/posts_tags?filter[posts_id][_eq]=${postId}`);
// //       const postTags = response.data.data.map(tag => tag.tags_id);
// //       setPostTags(postTags);
// //     } catch (error) {
// //       console.error('Error fetching post tags:', error);
// //     }
// //   };

// //   const fetchRelatedPosts = async (postTags) => {
// //     try {
// //       // Fetch all posts-tags relationships
// //       const response = await axios.get('https://admin.riderscars.com/items/posts_tags');
// //       const postsTagsData = response.data.data;
  
// //       // Filter related posts based on matching tags and exclude the current post
// //       const relatedPostsData = postsTagsData.filter(postTag => {
// //         return postTags.includes(postTag.tags_id) && postTag.posts_id !== post.id;
// //       });
  
// //       // Fetch detailed post information for related posts
// //       const promises = relatedPostsData.map(async relatedPost => {
// //         try {
// //           const postResponse = await axios.get(`https://admin.riderscars.com/items/posts/${relatedPost.posts_id}`);
// //           const postData = postResponse.data.data;
// //           const imageUrl = postData.image ? await fetchImageUrl(postData.image) : null;
// //           return { ...postData, imageUrl };
// //         } catch (error) {
// //           console.error(`Error fetching post ${relatedPost.posts_id}:`, error);
// //           return null; 
// //         }
// //       });
  
// //       const relatedPostsInfo = await Promise.all(promises);
  
// //       const validRelatedPosts = relatedPostsInfo.filter(post => post !== null);
// //       const finalRelatedPosts = validRelatedPosts.slice(0, 3);
  
// //       setRelatedPosts(finalRelatedPosts);
// //     } catch (error) {
// //       console.error('Error fetching related posts:', error);
// //     }
// //   };

// //   const fetchImageUrl = async (imageId) => {
// //     try {
// //       const response = await axios.get(`https://admin.riderscars.com/files/${imageId}`);
// //       return `https://admin.riderscars.com/assets/${response.data.data.filename_disk}`;
// //     } catch (error) {
// //       console.error('Error fetching image URL:', error);
// //       return null;
// //     }
// //   };

// //   const changeLanguage = (lng) => {
// //     i18n.changeLanguage(lng);
// //   };

// //   const isRTL = i18n.language === 'ar';

// //   const renderFile = (fileId) => (
// //     <div
// //       key={fileId}
// //       className={styles.imageContainer}
// //       style={{ backgroundImage: `url(https://admin.riderscars.com/assets/${fileId})` }}
// //     ></div>
// //   );


// //   const handleImageClick = (slug, imageUrl) => {
// //     window.scrollTo(0, 0);
// //     navigate(`/post/${slug}`, { state: { imageUrl } }); 
// //   };


// //   const getFontFamily = () => {
// //     if (post) {
// //       switch (post.post_language) {
// //         case 'arabic':
// //           return 'Almarai'; 
// //         case 'english':
// //           return 'Poppins'; 
// //         default:
// //           break;
// //       }
// //     }
  
// //     switch (i18n.language) {
// //       case 'ar':
// //         return 'Almarai'; 
// //       case 'en':
// //       default:
// //         return 'Poppins'; 
// //     }
// //   };
  

 
  
// //   const sanitizedContent = post ? DOMPurify.sanitize(`${post.content}`) : '';

  

// //   return (
// //     <div  className={`text-center ${styles.all} ${theme === 'light' ? styles.light : styles.dark}`}>
// //       <div className={styles.header} >
// //         <MDBContainer className={`${styles.contain} ${isRTL ? styles.containRtl : ''}`}>
          
          
// //           {post ? (
// //             <div className={`${styles['header-content']} ${isRTL ? styles['header-contentRtl'] : ''}`}>
             
// //    <h1  style={{ fontFamily: getFontFamily() }} className={styles['post-title']}>{post.title}</h1>
// //               <p style={{ fontFamily: getFontFamily() }}>{new Date(post.date_published).toLocaleDateString()}</p>

// //             </div>
// //           ) : (
// //             <p>Loading...</p>
// //           )}
// //         </MDBContainer>
// //       </div>

// //       <MDBContainer   className={`${styles.postContainer} ${isRTL ? styles.containRtl : ''}`}>
      
// // {post ? (
// //   <div   style={{ fontFamily: getFontFamily() }} className={`${styles['post-card']} ${theme === 'light' ? styles.light : styles.dark} ${isRTL ? styles.rtl : ''}`}>
// // <MDBRow className={`${styles['post-content']} ${theme === 'light' ? styles.light : styles.dark}`}>
// //             <MDBCol md="12">
// //   <div
// //     className={styles['post-text']}
  
// //     dangerouslySetInnerHTML={{ __html: sanitizedContent }} 
// //   />
// // </MDBCol>

// //             </MDBRow>

// //     <MDBRow className='mx-2'>
// //       {files.length > 0 ? (
// //         files.map((file, index) => (
// //           <MDBCol md="6" className={`mb-3 ${files.length === 1 ? styles.singleImage : ''}`} key={file.directus_files_id}>
// //             {renderFile(file.directus_files_id)}
// //           </MDBCol>
// //         ))
// //       ) : (
// //         <div className="my-3">
// //       </div>
// //       )}
      
// //     </MDBRow>








// //   </div>
// // ) : (
// //   <p>Loading...</p>
// // )}



// // <MDBContainer fluid  className={`text-center ${styles['popular-topics']} ${isRTL ? styles.rtl : ''} ${theme === 'light' ? styles.light : styles.dark}`}>
// //         <h2>{t('POPLUR2')}</h2>
// //           <div className={styles['second-posts-container']}>
// //             {relatedPosts.map((relatedPost) => (
// //               <MDBCol md="3" key={relatedPost.id} className="mx-4">
// //                 <div  className={`${styles['second-post-card']}   ${isRTL ? styles.rtlSecond : ''}`} onClick={() => handleImageClick(relatedPost.slug, relatedPost.imageUrl)}>
// //                   <div className={styles['imageContainer2']}>
// //                     {relatedPost.imageUrl ? (
// //                       <MDBCardImage
// //                         src={relatedPost.imageUrl}
// //                         alt={relatedPost.title}
// //                         className={styles['cardImage2']}
// //                       />
// //                     ) : (
// //                       <div className={styles['imagePlaceholder']}>
// //                         No Image Available
// //                       </div>
// //                     )}
// //                     <MDBBadge
// //                       pill
// //                       style={{ backgroundColor: categories.find(category => category.id === post.category)?.color }}
// //                       className={styles['categoryBadge2']}
// //                     >
// //                       {i18n.language === 'ar' ? categories.find(category => category.id === post.category)?.translatedTitle : categories.find(category => category.id === post.category)?.title}
// //                     </MDBBadge>
// //                   </div>
// //                   <div className={styles['second-post-content']}>
// //                     <p  className={`${styles['second-post-date']} ${i18n.language === 'ar' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''}  ${i18n.language === 'en' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''}`}>{new Date(relatedPost.date_published).toLocaleDateString()}</p>
// //                     <h3 className={`${styles['second-post-title']} ${i18n.language === 'ar' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''}  ${i18n.language === 'en' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''}`}>{relatedPost.title}</h3>
// //                     <h3 className={`${styles['second-post-title']} ${i18n.language === 'ar' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''}  ${i18n.language === 'en' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''}`} dangerouslySetInnerHTML={{ __html: relatedPost.summary }}></h3>
// //                   </div>
// //                 </div>
// //               </MDBCol>
// //             ))}
// //           </div>
// //         </MDBContainer>
// //       </MDBContainer>
// //     </div>
// //   );
// // };

// // export default PostDetail;

// // import React, { useEffect, useState } from 'react';
// // import styles from './PostDetail.module.css';
// // import axios from 'axios';
// // import { useParams, useLocation, useNavigate } from 'react-router-dom';
// // import { useTheme } from '../../themes/ThemesContext';
// // import { MDBContainer, MDBRow, MDBCol, MDBCardImage, MDBBadge ,MDBIcon } from 'mdb-react-ui-kit';
// // import { useTranslation } from 'react-i18next';
// // import DOMPurify from 'dompurify'; 
// // import { FacebookShare, TwitterShare, LinkedinShare } from 'react-share-kit';

// // const PostDetail = () => {
// //   const { t, i18n } = useTranslation();
// //   const { theme } = useTheme();
// //   const { slug } = useParams();
// //   const location = useLocation();
// //   const navigate = useNavigate(); 

// //   const [post, setPost] = useState(null);
// //   const [categories, setCategories] = useState([]);
// //   const [files, setFiles] = useState([]);
// //   const [relatedPosts, setRelatedPosts] = useState([]);
// //   const [postTags, setPostTags] = useState([]);
// //   const [isCopied, setIsCopied] = useState(false);

// //   useEffect(() => {
// //     window.scrollTo(0, 0);
// //   }, []);

// //   useEffect(() => {
// //     fetchPostDetail();
// //     fetchCategories();
// //   }, [slug]);

// //   useEffect(() => {
// //     if (post) {
// //       fetchPostTags(post.id);
// //     }
// //   }, [post]);

// //   useEffect(() => {
// //     if (postTags.length > 0) {
// //       fetchRelatedPosts(postTags);
// //     }
// //   }, [postTags]);

// //   const fetchPostDetail = async () => {
// //     try {
// //       const response = await axios.get(`https://admin.riderscars.com/items/posts?filter[slug][_eq]=${slug}`);
// //       const postData = response.data.data[0];
// //       const postFiles = await fetchPostFiles(postData.id);
// //       setPost(postData);
// //       setFiles(postFiles);
// //     } catch (error) {
// //       console.error('Error fetching post detail:', error);
// //     }
// //   };

// //   const fetchPostFiles = async (postId) => {
// //     try {
// //       const response = await axios.get(`https://admin.riderscars.com/items/posts_files?filter[posts_id][_eq]=${postId}`);
// //       return response.data.data.map(file => ({
// //         id: file.id,
// //         directus_files_id: file.directus_files_id
// //       }));
// //     } catch (error) {
// //       console.error('Error fetching post files:', error);
// //       return [];
// //     }
// //   };

// //   const fetchCategories = async () => {
// //     try {
// //       const response = await axios.get('https://admin.riderscars.com/items/Post_category');
// //       const categoriesData = response.data.data;

// //       const translationsResponse = await axios.get('https://admin.riderscars.com/items/Post_category_translations');
// //       const translationsData = translationsResponse.data.data;

// //       const categoriesWithTranslations = categoriesData.map(category => {
// //         const translation = translationsData.find(tr => tr.Post_category_id === category.id);
// //         if (translation) {
// //           return { ...category, translatedTitle: translation.title };
// //         } else {
// //           return { ...category, translatedTitle: category.title };
// //         }
// //       });

// //       setCategories(categoriesWithTranslations);
// //     } catch (error) {
// //       console.error('Error fetching categories:', error);
// //     }
// //   };

// //   const fetchPostTags = async (postId) => {
// //     try {
// //       const response = await axios.get(`https://admin.riderscars.com/items/posts_tags?filter[posts_id][_eq]=${postId}`);
// //       const postTags = response.data.data.map(tag => tag.tags_id);
// //       setPostTags(postTags);
// //     } catch (error) {
// //       console.error('Error fetching post tags:', error);
// //     }
// //   };

// //   const fetchRelatedPosts = async (postTags) => {
// //     try {
// //       const response = await axios.get('https://admin.riderscars.com/items/posts_tags');
// //       const postsTagsData = response.data.data;

// //       const relatedPostsData = postsTagsData.filter(postTag => {
// //         return postTags.includes(postTag.tags_id) && postTag.posts_id !== post.id;
// //       });

// //       const promises = relatedPostsData.map(async relatedPost => {
// //         try {
// //           const postResponse = await axios.get(`https://admin.riderscars.com/items/posts/${relatedPost.posts_id}`);
// //           const postData = postResponse.data.data;
// //           const imageUrl = postData.image ? await fetchImageUrl(postData.image) : null;
// //           return { ...postData, imageUrl };
// //         } catch (error) {
// //           console.error(`Error fetching post ${relatedPost.posts_id}:`, error);
// //           return null;
// //         }
// //       });

// //       const relatedPostsInfo = await Promise.all(promises);

// //       const validRelatedPosts = relatedPostsInfo.filter(post => post !== null);
// //       const finalRelatedPosts = validRelatedPosts.slice(0, 3);

// //       setRelatedPosts(finalRelatedPosts);
// //     } catch (error) {
// //       console.error('Error fetching related posts:', error);
// //     }
// //   };

// //   const fetchImageUrl = async (imageId) => {
// //     try {
// //       const response = await axios.get(`https://admin.riderscars.com/files/${imageId}`);
// //       return `https://admin.riderscars.com/assets/${response.data.data.filename_disk}`;
// //     } catch (error) {
// //       console.error('Error fetching image URL:', error);
// //       return null;
// //     }
// //   };

// //   const isRTL = i18n.language === 'ar';

// //   const renderFile = (fileId) => (
// //     <div
// //       key={fileId}
// //       className={styles.imageContainer}
// //       style={{ backgroundImage: `url(https://admin.riderscars.com/assets/${fileId})` }}
// //     ></div>
// //   );

// //   const handleImageClick = (slug, imageUrl) => {
// //     window.scrollTo(0, 0);
// //     navigate(`/post/${slug}`, { state: { imageUrl } });
// //   };

// //   const getFontFamily = () => {
// //     if (post) {
// //       switch (post.post_language) {
// //         case 'arabic':
// //           return 'Almarai';
// //         case 'english':
// //           return 'Poppins';
// //         default:
// //           break;
// //       }
// //     }

// //     switch (i18n.language) {
// //       case 'ar':
// //         return 'Almarai';
// //       case 'en':
// //       default:
// //         return 'Poppins';
// //     }
// //   };

// //   const sanitizedContent = post ? DOMPurify.sanitize(`${post.content}`) : '';

// //   const currentUrl = window.location.href;

// //   const titleToShare = post ? `Check out this amazing post: ${post.title}` : '';

// //   const handleCopyClick = () => {
// //     navigator.clipboard.writeText(currentUrl);
// //     setIsCopied(true);
// //     setTimeout(() => {
// //       setIsCopied(false);
// //     }, 2000);
// //   };

// //   const detectLanguage = (text) => {
// //     const arabicPattern = /[\u0600-\u06FF]/;
// //     return arabicPattern.test(text) ? 'ar' : 'en';
// //   };

// //   return (
// //     <div className={`text-center ${styles.all} ${theme === 'light' ? styles.light : styles.dark}`}>
// //       <div className={styles.header}>
// //         <MDBContainer className={`${styles.contain} ${isRTL ? styles.containRtl : ''}`}>
// //           {post ? (
// //             <div className={`${styles['header-content']} ${isRTL ? styles['header-contentRtl'] : ''}`}>
// //               <h1 style={{ fontFamily: getFontFamily() }} className={styles['post-title']}>{post.title}</h1>
// //               <p style={{ fontFamily: getFontFamily() }}>{new Date(post.date_published).toLocaleDateString()}</p>
// //             </div>
// //           ) : (
// //             <p>Loading...</p>
// //           )}
// //         </MDBContainer>
// //       </div>

// //       <MDBContainer className={`${styles.postContainer} ${isRTL ? styles.containRtl : ''}`}>
// //         {post ? (
// //           <div style={{ fontFamily: getFontFamily() }} className={`${styles['post-card']} ${theme === 'light' ? styles.light : styles.dark} ${isRTL ? styles.rtl : ''}`}>
// //             <MDBRow className={`${styles['post-content']} ${theme === 'light' ? styles.light : styles.dark}`}>
// //               <MDBCol md="12">
// //                 <div
// //                   className={styles['post-text']}
// //                   dangerouslySetInnerHTML={{ __html: sanitizedContent }}
// //                 />
// //               </MDBCol>
// //             </MDBRow>

// //             <MDBRow className='mx-2'>
// //               {files.length > 0 ? (
// //                 files.map((file, index) => (
// //                   <MDBCol md="6" className={`mb-3 ${files.length === 1 ? styles.singleImage : ''}`} key={file.directus_files_id}>
// //                     {renderFile(file.directus_files_id)}
// //                   </MDBCol>
// //                 ))
// //               ) : (
// //                 <div className="my-3"></div>
// //               )}
// //             </MDBRow>
// //             <MDBContainer className={styles.shareContainer}>
// //               <div className={styles.shareText}>{t('shareOnSocial')}</div>
// //               <div className={styles.urlContainer} onClick={handleCopyClick}>
// //                 <div className={styles.urlInput}>
// //                   {isCopied ? t('Copied') : t('copylink')}
// //                 </div>
// //                 <MDBIcon fas icon="link" className={styles.copyButton} />
// //               </div>
// //               <div className={styles.socialButtons}>
// //                 <FacebookShare url={currentUrl} quote={titleToShare}  borderRadius={7} className={styles.facebookButton} />
// //                 <TwitterShare url={currentUrl} title={titleToShare} borderRadius={7}  className={styles.twitterButton} />
// //                 <LinkedinShare url={currentUrl} title={titleToShare}  borderRadius={7}  className={styles.linkedinButton} />
// //               </div>
// //             </MDBContainer>
// //           </div>
// //         ) : (
// //           <p>Loading...</p>
// //         )}

// //         <MDBContainer fluid className={`text-center ${styles['popular-topics']} ${isRTL ? styles.rtl : ''} ${theme === 'light' ? styles.light : styles.dark}`}>
// //           <h2>{t('POPLUR2')}</h2>
// //           <div className={styles['second-posts-container']}>
// //             {relatedPosts.map((relatedPost) => (
// //               <MDBCol md="3" key={relatedPost.id} className="mx-4">
// //                 <div className={`${styles['second-post-card']} ${isRTL ? styles.rtlSecond : ''}`} onClick={() => handleImageClick(relatedPost.slug, relatedPost.imageUrl)}>
// //                   <div className={styles['imageContainer2']}>
// //                     {relatedPost.imageUrl ? (
// //                       <MDBCardImage
// //                         src={relatedPost.imageUrl}
// //                         alt={relatedPost.title}
// //                         className={styles['cardImage2']}
// //                       />
// //                     ) : (
// //                       <div className={styles['imagePlaceholder']}>
// //                         No Image Available
// //                       </div>
// //                     )}
// //                     <MDBBadge
// //                       pill
// //                       style={{ backgroundColor: categories.find(category => category.id === post.category)?.color }}
// //                       className={styles['categoryBadge2']}
// //                     >
// //                       {detectLanguage(relatedPost.title) === 'ar' ? categories.find(category => category.id === relatedPost.category)?.translatedTitle : categories.find(category => category.id === relatedPost.category)?.title}
// //                     </MDBBadge>
// //                   </div>
// //                   <div className={styles['second-post-content']}>
// //                     <p className={`${styles['second-post-date']} ${i18n.language === 'ar' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''} ${i18n.language === 'en' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''}`}>{new Date(relatedPost.date_published).toLocaleDateString()}</p>
// //                     <h3 className={`${styles['second-post-title']} ${i18n.language === 'ar' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''} ${i18n.language === 'en' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''}`}>{relatedPost.title}</h3>
// //                   </div>
// //                 </div>
// //               </MDBCol>
// //             ))}
// //           </div>
// //         </MDBContainer>
// //       </MDBContainer>
// //     </div>
// //   );
// // };

// // export default PostDetail;


// import React, { useEffect, useState } from 'react';
// import { Helmet } from 'react-helmet';
// import styles from './PostDetail.module.css';
// import axios from 'axios';
// import { useParams, useLocation, useNavigate } from 'react-router-dom';
// import { useTheme } from '../../themes/ThemesContext';
// import { MDBContainer, MDBRow, MDBCol, MDBCardImage, MDBBadge, MDBIcon } from 'mdb-react-ui-kit';
// import { useTranslation } from 'react-i18next';
// import DOMPurify from 'dompurify';
// import { FacebookShare, TwitterShare, LinkedinShare } from 'react-share-kit';

// const PostDetail = () => {
//   const { t, i18n } = useTranslation();
//   const { theme } = useTheme();
//   const { slug } = useParams();
//   const location = useLocation();
//   const navigate = useNavigate();

//   const [post, setPost] = useState(null);
//   const [categories, setCategories] = useState([]);
//   const [files, setFiles] = useState([]);
//   const [relatedPosts, setRelatedPosts] = useState([]);
//   const [postTags, setPostTags] = useState([]);
//   const [isCopied, setIsCopied] = useState(false);

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   useEffect(() => {
//     fetchPostDetail();
//     fetchCategories();
//   }, [slug]);

//   useEffect(() => {
//     if (post) {
//       fetchPostTags(post.id);
//     }
//   }, [post]);

//   useEffect(() => {
//     if (postTags.length > 0) {
//       fetchRelatedPosts(postTags);
//     }
//   }, [postTags]);

//   const fetchPostDetail = async () => {
//     try {
//       const response = await axios.get(`https://admin.riderscars.com/items/posts?filter[slug][_eq]=${slug}`);
//       const postData = response.data.data[0];
//       const postFiles = await fetchPostFiles(postData.id);
//       setPost(postData);
//       setFiles(postFiles);
//     } catch (error) {
//       console.error('Error fetching post detail:', error);
//     }
//   };

//   const fetchPostFiles = async (postId) => {
//     try {
//       const response = await axios.get(`https://admin.riderscars.com/items/posts_files?filter[posts_id][_eq]=${postId}`);
//       return response.data.data.map(file => ({
//         id: file.id,
//         directus_files_id: file.directus_files_id
//       }));
//     } catch (error) {
//       console.error('Error fetching post files:', error);
//       return [];
//     }
//   };

//   const fetchCategories = async () => {
//     try {
//       const response = await axios.get('https://admin.riderscars.com/items/Post_category');
//       const categoriesData = response.data.data;

//       const translationsResponse = await axios.get('https://admin.riderscars.com/items/Post_category_translations');
//       const translationsData = translationsResponse.data.data;

//       const categoriesWithTranslations = categoriesData.map(category => {
//         const translation = translationsData.find(tr => tr.Post_category_id === category.id);
//         if (translation) {
//           return { ...category, translatedTitle: translation.title };
//         } else {
//           return { ...category, translatedTitle: category.title };
//         }
//       });

//       setCategories(categoriesWithTranslations);
//     } catch (error) {
//       console.error('Error fetching categories:', error);
//     }
//   };

//   const fetchPostTags = async (postId) => {
//     try {
//       const response = await axios.get(`https://admin.riderscars.com/items/posts_tags?filter[posts_id][_eq]=${postId}`);
//       const postTags = response.data.data.map(tag => tag.tags_id);
//       setPostTags(postTags);
//     } catch (error) {
//       console.error('Error fetching post tags:', error);
//     }
//   };

//   const fetchRelatedPosts = async (postTags) => {
//     try {
//       const response = await axios.get('https://admin.riderscars.com/items/posts_tags');
//       const postsTagsData = response.data.data;

//       const relatedPostsData = postsTagsData.filter(postTag => {
//         return postTags.includes(postTag.tags_id) && postTag.posts_id !== post.id;
//       });

//       const promises = relatedPostsData.map(async relatedPost => {
//         try {
//           const postResponse = await axios.get(`https://admin.riderscars.com/items/posts/${relatedPost.posts_id}`);
//           const postData = postResponse.data.data;
//           const imageUrl = postData.image ? await fetchImageUrl(postData.image) : null;
//           return { ...postData, imageUrl };
//         } catch (error) {
//           console.error(`Error fetching post ${relatedPost.posts_id}:`, error);
//           return null;
//         }
//       });

//       const relatedPostsInfo = await Promise.all(promises);

//       const validRelatedPosts = relatedPostsInfo.filter(post => post !== null);
//       const finalRelatedPosts = validRelatedPosts.slice(0, 3);

//       setRelatedPosts(finalRelatedPosts);
//     } catch (error) {
//       console.error('Error fetching related posts:', error);
//     }
//   };

//   const fetchImageUrl = async (imageId) => {
//     try {
//       const response = await axios.get(`https://admin.riderscars.com/files/${imageId}`);
//       return `https://admin.riderscars.com/assets/${response.data.data.filename_disk}`;
//     } catch (error) {
//       console.error('Error fetching image URL:', error);
//       return null;
//     }
//   };

//   const isRTL = i18n.language === 'ar';

//   const renderFile = (fileId) => (
//     <div
//       key={fileId}
//       className={styles.imageContainer}
//       style={{ backgroundImage: `url(https://admin.riderscars.com/assets/${fileId})` }}
//     ></div>
//   );

//   const handleImageClick = (slug, imageUrl) => {
//     window.scrollTo(0, 0);
//     navigate(`/post/${slug}`, { state: { imageUrl } });
//   };

//   const getFontFamily = () => {
//     if (post) {
//       switch (post.post_language) {
//         case 'arabic':
//           return 'Almarai';
//         case 'english':
//           return 'Poppins';
//         default:
//           break;
//       }
//     }

//     switch (i18n.language) {
//       case 'ar':
//         return 'Almarai';
//       case 'en':
//       default:
//         return 'Poppins';
//     }
//   };

//   const sanitizedContent = post ? DOMPurify.sanitize(`${post.content}`) : '';

//   const currentUrl = window.location.href;

//   const titleToShare = post ? `${post.title}` : '';

//   const handleCopyClick = () => {
//     navigator.clipboard.writeText(currentUrl);
//     setIsCopied(true);
//     setTimeout(() => {
//       setIsCopied(false);
//     }, 2000);
//   };

//   const detectLanguage = (text) => {
//     const arabicPattern = /[\u0600-\u06FF]/;
//     return arabicPattern.test(text) ? 'ar' : 'en';
//   };

//   return (
//     <div className={`text-center ${styles.all} ${theme === 'light' ? styles.light : styles.dark}`}>
//       {post && (
//         <Helmet>
//           <title>{post.title}</title>
//           <meta property="og:title" content={post.title} />
//           <meta property="og:image" content={`https://admin.riderscars.com/assets/${files.length > 0 ? files[0].directus_files_id : ''}`} />
//           <meta property="og:url" content={currentUrl} />
//         </Helmet>
//       )}
//       <div className={styles.header}>
//         <MDBContainer className={`${styles.contain} ${isRTL ? styles.containRtl : ''}`}>
//           {post ? (
//             <div className={`${styles['header-content']} ${isRTL ? styles['header-contentRtl'] : ''}`}>
//               <h1 style={{ fontFamily: getFontFamily() }} className={styles['post-title']}>{post.title}</h1>
//               <p style={{ fontFamily: getFontFamily() }}>{new Date(post.date_published).toLocaleDateString()}</p>
//             </div>
//           ) : (
//             <p>Loading...</p>
//           )}
//         </MDBContainer>
//       </div>

//       <MDBContainer className={`${styles.postContainer} ${isRTL ? styles.containRtl : ''}`}>
//         {post ? (
//           <div style={{ fontFamily: getFontFamily() }} className={`${styles['post-card']} ${theme === 'light' ? styles.light : styles.dark} ${isRTL ? styles.rtl : ''}`}>
//             <MDBRow className={`${styles['post-content']} ${theme === 'light' ? styles.light : styles.dark}`}>
//               <MDBCol md="12">
//                 <div
//                   className={styles['post-text']}
//                   dangerouslySetInnerHTML={{ __html: sanitizedContent }}
//                 />
//               </MDBCol>
//             </MDBRow>

//             <MDBRow className='mx-2'>
//               {files.length > 0 ? (
//                 files.map((file, index) => (
//                   <MDBCol md="6" className={`mb-3 ${files.length === 1 ? styles.singleImage : ''}`} key={file.directus_files_id}>
//                     {renderFile(file.directus_files_id)}
//                   </MDBCol>
//                 ))
//               ) : (
//                 <div className="my-3"></div>
//               )}
//             </MDBRow>
//             <MDBContainer className={styles.shareContainer}>
//               <div className={styles.shareText}>{t('shareOnSocial')}</div>
//               <div className={styles.urlContainer} onClick={handleCopyClick}>
//                 <div className={styles.urlInput}>
//                   {isCopied ? t('Copied') : t('copylink')}
//                 </div>
//                 <MDBIcon fas icon="link" className={styles.copyButton} />
//               </div>
//               <div className={styles.socialButtons}>
//                 <FacebookShare url={currentUrl} quote={titleToShare} borderRadius={7} className={styles.facebookButton} />
//                 <TwitterShare url={currentUrl} title={titleToShare} borderRadius={7} className={styles.twitterButton} />
//                 <LinkedinShare url={currentUrl} title={titleToShare} borderRadius={7} className={styles.linkedinButton} />
//               </div>
//             </MDBContainer>
//           </div>
//         ) : (
//           <p>Loading...</p>
//         )}

//         <MDBContainer fluid className={`text-center ${styles['popular-topics']} ${isRTL ? styles.rtl : ''} ${theme === 'light' ? styles.light : styles.dark}`}>
//           <h2>{t('POPLUR2')}</h2>
//           <div className={styles['second-posts-container']}>
//             {relatedPosts.map((relatedPost) => (
//               <MDBCol md="3" key={relatedPost.id} className="mx-4">
//                 <div className={`${styles['second-post-card']} ${isRTL ? styles.rtlSecond : ''}`} onClick={() => handleImageClick(relatedPost.slug, relatedPost.imageUrl)}>
//                   <div className={styles['imageContainer2']}>
//                     {relatedPost.imageUrl ? (
//                       <MDBCardImage
//                         src={relatedPost.imageUrl}
//                         alt={relatedPost.title}
//                         className={styles['cardImage2']}
//                       />
//                     ) : (
//                       <div className={styles['imagePlaceholder']}>
//                         No Image Available
//                       </div>
//                     )}
//                     <MDBBadge
//                       pill
//                       style={{ backgroundColor: categories.find(category => category.id === post.category)?.color }}
//                       className={styles['categoryBadge2']}
//                     >
//                       {detectLanguage(relatedPost.title) === 'ar' ? categories.find(category => category.id === relatedPost.category)?.translatedTitle : categories.find(category => category.id === relatedPost.category)?.title}
//                     </MDBBadge>
//                   </div>
//                   <div className={styles['second-post-content']}>
//                     <p className={`${styles['second-post-date']} ${i18n.language === 'ar' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''} ${i18n.language === 'en' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''}`}>{new Date(relatedPost.date_published).toLocaleDateString()}</p>
//                     <h3 className={`${styles['second-post-title']} ${i18n.language === 'ar' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''} ${i18n.language === 'en' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''}`}>{relatedPost.title}</h3>
//                   </div>
//                 </div>
//               </MDBCol>
//             ))}
//           </div>
//         </MDBContainer>
//       </MDBContainer>
//     </div>
//   );
// };

// export default PostDetail;
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import styles from './PostDetail.module.css';
import axios from 'axios';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '../../themes/ThemesContext';
import { MDBContainer, MDBRow, MDBCol, MDBCardImage, MDBBadge, MDBIcon } from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import { FacebookShare, TwitterShare, LinkedinShare } from 'react-share-kit';
import Loading from '../../components/Loading/LodingPage'; // Import your loading component

const PostDetail = () => {
  const { t, i18n } = useTranslation();
  const { theme } = useTheme();
  const { slug } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [post, setPost] = useState(null);
  const [categories, setCategories] = useState([]);
  const [files, setFiles] = useState([]);
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [postTags, setPostTags] = useState([]);
  const [isCopied, setIsCopied] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state here

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchPostDetail();
    fetchCategories();
  }, [slug]);

  useEffect(() => {
    if (post) {
      fetchPostTags(post.id);
    }
  }, [post]);

  useEffect(() => {
    if (postTags.length > 0) {
      fetchRelatedPosts(postTags);
    }
  }, [postTags]);

  const fetchPostDetail = async () => {
    try {
      const response = await axios.get(`https://admin.riderscars.com/items/posts?filter[slug][_eq]=${slug}`);
      const postData = response.data.data[0];
      const postFiles = await fetchPostFiles(postData.id);
      setPost(postData);
      setFiles(postFiles);
      setLoading(false); // Set loading to false after fetching posts
    } catch (error) {
      console.error('Error fetching post detail:', error);
      setLoading(false); // Set loading to false even if there's an error
    }
  };

  const fetchPostFiles = async (postId) => {
    try {
      const response = await axios.get(`https://admin.riderscars.com/items/posts_files?filter[posts_id][_eq]=${postId}`);
      return response.data.data.map(file => ({
        id: file.id,
        directus_files_id: file.directus_files_id
      }));
    } catch (error) {
      console.error('Error fetching post files:', error);
      return [];
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get('https://admin.riderscars.com/items/Post_category');
      const categoriesData = response.data.data;

      const translationsResponse = await axios.get('https://admin.riderscars.com/items/Post_category_translations');
      const translationsData = translationsResponse.data.data;

      const categoriesWithTranslations = categoriesData.map(category => {
        const translation = translationsData.find(tr => tr.Post_category_id === category.id);
        if (translation) {
          return { ...category, translatedTitle: translation.title };
        } else {
          return { ...category, translatedTitle: category.title };
        }
      });

      setCategories(categoriesWithTranslations);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchPostTags = async (postId) => {
    try {
      const response = await axios.get(`https://admin.riderscars.com/items/posts_tags?filter[posts_id][_eq]=${postId}`);
      const postTags = response.data.data.map(tag => tag.tags_id);
      setPostTags(postTags);
    } catch (error) {
      console.error('Error fetching post tags:', error);
    }
  };

  const fetchRelatedPosts = async (postTags) => {
    try {
      const response = await axios.get('https://admin.riderscars.com/items/posts_tags');
      const postsTagsData = response.data.data;

      const relatedPostsData = postsTagsData.filter(postTag => {
        return postTags.includes(postTag.tags_id) && postTag.posts_id !== post.id;
      });

      const promises = relatedPostsData.map(async relatedPost => {
        try {
          const postResponse = await axios.get(`https://admin.riderscars.com/items/posts/${relatedPost.posts_id}`);
          const postData = postResponse.data.data;
          const imageUrl = postData.image ? await fetchImageUrl(postData.image) : null;
          return { ...postData, imageUrl };
        } catch (error) {
          console.error(`Error fetching post ${relatedPost.posts_id}:`, error);
          return null;
        }
      });

      const relatedPostsInfo = await Promise.all(promises);

      const validRelatedPosts = relatedPostsInfo.filter(post => post !== null);
      const finalRelatedPosts = validRelatedPosts.slice(0, 3);

      setRelatedPosts(finalRelatedPosts);
    } catch (error) {
      console.error('Error fetching related posts:', error);
    }
  };

  const fetchImageUrl = async (imageId) => {
    try {
      const response = await axios.get(`https://admin.riderscars.com/files/${imageId}`);
      return `https://admin.riderscars.com/assets/${response.data.data.filename_disk}`;
    } catch (error) {
      console.error('Error fetching image URL:', error);
      return null;
    }
  };

  const isRTL = i18n.language === 'ar';

  const renderFile = (fileId) => (
    <div
      key={fileId}
      className={styles.imageContainer}
      style={{ backgroundImage: `url(https://admin.riderscars.com/assets/${fileId})` }}
    ></div>
  );

  const handleImageClick = (slug, imageUrl) => {
    window.scrollTo(0, 0);
    navigate(`/post/${slug}`, { state: { imageUrl } });
  };

  const getFontFamily = () => {
    if (post) {
      switch (post.post_language) {
        case 'arabic':
          return 'Almarai';
        case 'english':
          return 'Poppins';
        default:
          break;
      }
    }

    switch (i18n.language) {
      case 'ar':
        return 'Almarai';
      case 'en':
      default:
        return 'Poppins';
    }
  };

  const sanitizedContent = post ? DOMPurify.sanitize(`${post.content}`) : '';

  const currentUrl = window.location.href;

  const titleToShare = post ? `${post.title}` : '';

  const handleCopyClick = () => {
    navigator.clipboard.writeText(currentUrl);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const detectLanguage = (text) => {
    const arabicPattern = /[\u0600-\u06FF]/;
    return arabicPattern.test(text) ? 'ar' : 'en';
  };

  return (
    <div className={`text-center ${styles.all} ${theme === 'light' ? styles.light : styles.dark}`}>
      {loading ? (
        <Loading /> // Show loading component while fetching post details
      ) : (
        <>
          {post && (
            <Helmet>
              <title>{post.title}</title>
              <meta property="og:title" content={post.title} />
              <meta property="og:image" content={`https://admin.riderscars.com/assets/${files.length > 0 ? files[0].directus_files_id : ''}`} />
              <meta property="og:url" content={currentUrl} />
            </Helmet>
          )}
          <div className={styles.header}>
            <MDBContainer className={`${styles.contain} ${isRTL ? styles.containRtl : ''}`}>
              {post ? (
                <div className={`${styles['header-content']} ${isRTL ? styles['header-contentRtl'] : ''}`}>
                  <h1 style={{ fontFamily: getFontFamily() }} className={styles['post-title']}>{post.title}</h1>
                  <p style={{ fontFamily: getFontFamily() }}>{new Date(post.date_published).toLocaleDateString()}</p>
                </div>
              ) : (
                <p>Loading...</p>
              )}
            </MDBContainer>
          </div>

          <MDBContainer className={`${styles.postContainer} ${isRTL ? styles.containRtl : ''}`}>
            {post ? (
              <div style={{ fontFamily: getFontFamily() }} className={`${styles['post-card']} ${theme === 'light' ? styles.light : styles.dark} ${isRTL ? styles.rtl : ''}`}>
                <MDBRow className={`${styles['post-content']} ${theme === 'light' ? styles.light : styles.dark}`}>
                  <MDBCol md="12">
                    <div
                      className={styles['post-text']}
                      dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                    />
                  </MDBCol>
                </MDBRow>

                <MDBRow className='mx-2'>
                  {files.length > 0 ? (
                    files.map((file, index) => (
                      <MDBCol md="6" className={`mb-3 ${files.length === 1 ? styles.singleImage : ''}`} key={file.directus_files_id}>
                        {renderFile(file.directus_files_id)}
                      </MDBCol>
                    ))
                  ) : (
                    <div className="my-3"></div>
                  )}
                </MDBRow>
                <MDBContainer className={styles.shareContainer}>
                  <div className={styles.shareText}>{t('shareOnSocial')}</div>
                  <div className={styles.urlContainer} onClick={handleCopyClick}>
                    <div className={styles.urlInput}>
                      {isCopied ? t('Copied') : t('copylink')}
                    </div>
                    <MDBIcon fas icon="link" className={styles.copyButton} />
                  </div>
                  <div className={styles.socialButtons}>
                    <FacebookShare url={currentUrl} quote={titleToShare} borderRadius={7} className={styles.facebookButton} />
                    <TwitterShare url={currentUrl} title={titleToShare} borderRadius={7} className={styles.twitterButton} />
                    <LinkedinShare url={currentUrl} title={titleToShare} borderRadius={7} className={styles.linkedinButton} />
                  </div>
                </MDBContainer>
              </div>
            ) : (
              <p>Loading...</p>
            )}

            <MDBContainer fluid className={`text-center ${styles['popular-topics']} ${isRTL ? styles.rtl : ''} ${theme === 'light' ? styles.light : styles.dark}`}>
              <h2>{t('POPLUR2')}</h2>
              <div className={styles['second-posts-container']}>
                {relatedPosts.map((relatedPost) => (
                  <MDBCol md="3" key={relatedPost.id} className="mx-4">
                    <div className={`${styles['second-post-card']} ${isRTL ? styles.rtlSecond : ''}`} onClick={() => handleImageClick(relatedPost.slug, relatedPost.imageUrl)}>
                      <div className={styles['imageContainer2']}>
                        {relatedPost.imageUrl ? (
                          <MDBCardImage
                            src={relatedPost.imageUrl}
                            alt={relatedPost.title}
                            className={styles['cardImage2']}
                          />
                        ) : (
                          <div className={styles['imagePlaceholder']}>
                            No Image Available
                          </div>
                        )}
                        <MDBBadge
                          pill
                          style={{ backgroundColor: categories.find(category => category.id === post.category)?.color }}
                          className={styles['categoryBadge2']}
                        >
                          {detectLanguage(relatedPost.title) === 'ar' ? categories.find(category => category.id === relatedPost.category)?.translatedTitle : categories.find(category => category.id === relatedPost.category)?.title}
                        </MDBBadge>
                      </div>
                      <div className={styles['second-post-content']}>
                        <p className={`${styles['second-post-date']} ${i18n.language === 'ar' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''} ${i18n.language === 'en' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''}`}>{new Date(relatedPost.date_published).toLocaleDateString()}</p>
                        <h3 className={`${styles['second-post-title']} ${i18n.language === 'ar' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''} ${i18n.language === 'en' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''}`}>{relatedPost.title}</h3>
                      </div>
                    </div>
                  </MDBCol>
                ))}
              </div>
            </MDBContainer>
          </MDBContainer>
        </>
      )}
    </div>
  );
};

export default PostDetail;
