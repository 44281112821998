

import React, { useState, useEffect } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
} from 'mdb-react-ui-kit';
import axios from 'axios';
import styles from './JoinUs.module.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../themes/ThemesContext';
import Loading from '../../components/Loading/LodingPage'; // Import your loading component

const JoinUs = () => {
  const { theme } = useTheme();
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [country, setCountry] = useState('');
  const [countries, setCountries] = useState([]);
  const [countryTranslations, setCountryTranslations] = useState({});
  const [partnershipType, setPartnershipType] = useState('');
  const [partnershipTypes, setPartnershipTypes] = useState([]);
  const [partnershipTranslations, setPartnershipTranslations] = useState({});
  const [phone, setPhone] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [selectedCountryName, setSelectedCountryName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [businessWebsite, setBusinessWebsite] = useState('');
  const [isPartnershipDropdownOpen, setIsPartnershipDropdownOpen] = useState(false);
  const [isCountryDropdownOpen, setIsCountryDropdownOpen] = useState(false);
  const [countrySearchTerm, setCountrySearchTerm] = useState('');
  const navigate = useNavigate();
  const isRTL = i18n.language === 'ar';
  const [generalError, setGeneralError] = useState(false);
  const [fullNameError, setFullNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [BnameError, setBnameError] = useState(null);
  const [BwebsiteError, setBwebsiteError] = useState(null);
  const [countryCode, setCountryCode] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [loading, setLoading] = useState(true); // Add loading state here

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get('https://admin.riderscars.com/items/countries?fields=*,flag.id,name&sort=name&limit=-1');
        setCountries(response.data.data);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    if (i18n.language === 'ar') {
      const fetchCountryTranslations = async () => {
        try {
          const response = await axios.get('https://admin.riderscars.com/items/countries_translations?fields=*,flag.id,name&sort=name&limit=-1');
          const translations = response.data.data.reduce((acc, item) => {
            if (item.countries_id) {
              acc[item.countries_id] = item.name;
            } else {
              acc[item.id] = item.name;
            }
            return acc;
          }, {});
          setCountryTranslations(translations);
        } catch (error) {
          console.error('Error fetching country translations:', error);
        }
      };

      fetchCountryTranslations();
    }
  }, [i18n.language]);

  useEffect(() => {
    const fetchPartnershipTypes = async () => {
      try {
        const response = await axios.get('https://admin.riderscars.com/items/partenership');
        setPartnershipTypes(response.data.data);
      } catch (error) {
        console.error('Error fetching partnership types:', error);
      }
    };

    const fetchPartnershipTranslations = async () => {
      try {
        const response = await axios.get('https://admin.riderscars.com/items/partenership_translations');
        const translations = response.data.data.reduce((acc, item) => {
          acc[item.partenership_id] = item.partenership_type;
          return acc;
        }, {});
        setPartnershipTranslations(translations);
      } catch (error) {
        console.error('Error fetching partnership translations:', error);
      }
    };

    fetchPartnershipTypes();
    fetchPartnershipTranslations();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      setGeneralError(true);
      return;
    }

    setGeneralError(false);
    setSubmitting(true);

    try {
      const newOrganization = {
        email,
        full_name: fullName,
        country,
        partenership: partnershipType,
        phone,
        Business_Name: businessName,
        URL: businessWebsite,
        status: 'Pending',
      };

      const response = await axios.post('https://admin.riderscars.com/items/Join_Us', newOrganization);

      if (response.status === 200 || response.status === 201 || response.status === 204) {
        showAlert(t('joinUsSuccess'), 'success');
        setTimeout(() => {
          navigate('/Home');
        }, 4000);
      } else {
        console.error('Unexpected response status:', response.status);
      }
    } catch (error) {
      console.error('There was an error submitting your request:', error.response ? error.response.data : error.message);
      showAlert(t('submitError'), 'error');
    }

    setSubmitting(false);
  };

  const validateForm = () => {
    let isValid = true;

    if (!fullName) {
      setFullNameError(t('fillFullName'));
      isValid = false;
    } else {
      setFullNameError(null);
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email)) {
      setEmailError(t('fillEmail'));
      isValid = false;
    } else {
      setEmailError(null);
    }

    const phoneRegex = /^\+\d{2,} \d{7,}$/;
    if (!phone || !phoneRegex.test(phone.trim())) {
      setPhoneError(t('fillPhone'));
      isValid = false;
    } else {
      setPhoneError(null);
    }

    if (['1', '2', '3'].includes(partnershipType.toString())) {
      if (!businessName) {
        setBnameError(t('fillBname'));
        isValid = false;
      } else {
        setBnameError(null);
      }
      if (!businessWebsite) {
        setBwebsiteError(t('fillBwebsite'));
        isValid = false;
      } else {
        setBwebsiteError(null);
      }
    }

    return isValid;
  };

  const showAlert = (message, type) => {
    setAlertMessage(message);
    setAlertType(type);
    setTimeout(() => {
      setAlertMessage('');
      setAlertType('');
    }, 5000);
  };

  const getFlagUrl = (flagId) => `https://admin.riderscars.com/assets/${flagId}`;

  const handleCountrySelect = (countryId, countryName) => {
    setCountry(countryId);
    setSelectedCountryName(i18n.language === 'ar' ? countryTranslations[countryId] || countryName : countryName);
    const selectedCountry = countries.find((country) => country.id === countryId);
    setCountryCode(selectedCountry.country_code); // Save country code in state
    setPhone(`${selectedCountry.country_code} `); // Add space after country code
    setIsCountryDropdownOpen(false);
  };

  const handlePartnershipTypeSelect = (typeId) => {
    setPartnershipType(typeId);
    setIsPartnershipDropdownOpen(false);
  };

  const getPartnershipTypeName = (typeId) => {
    if (i18n.language === 'ar') {
      return partnershipTranslations[typeId] || partnershipTypes.find((type) => type.id === typeId)?.partenership_type;
    }
    return partnershipTypes.find((type) => type.id === typeId)?.partenership_type;
  };

  const togglePartnershipDropdown = () => {
    setIsPartnershipDropdownOpen(!isPartnershipDropdownOpen);
  };

  const toggleCountryDropdown = () => {
    setIsCountryDropdownOpen(!isCountryDropdownOpen);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    setGeneralError(false);

    switch (id) {
      case 'formPhone':
        setPhone(value);

        if (!value) {
          setPhoneError(t('fillPhone'));
        } else {
          setPhoneError(null);
        }
        break;
      case 'formFullName':
        setFullName(value);
        if (!value) {
          setFullNameError(t('fillFullName'));
        } else {
          setFullNameError(null);
        }
        break;
      case 'formEmail':
        setEmail(value);
        if (!value) {
          setEmailError(t('fillEmail'));
        } else {
          setEmailError(null);
        }
        break;
      case 'formBusinessName':
        setBusinessName(value);
        if (!value) {
          setBnameError(t('fillBname'));
        } else {
          setBnameError(null);
        }
        break;
      case 'formBusinessWebsite':
        setBusinessWebsite(value);
        if (!value) {
          setBwebsiteError(t('fillBwebsite'));
        } else {
          setBwebsiteError(null);
        }
        break;
      default:
        break;
    }
  };

  const handleCountrySearchChange = (e) => {
    setCountrySearchTerm(e.target.value);
  };

  const filteredCountries = countries.filter((country) => {
    const countryName = i18n.language === 'ar' ? countryTranslations[country.id] || country.name : country.name;
    return countryName.toLowerCase().includes(countrySearchTerm.toLowerCase());
  });


    useEffect(() => {
    // Set loading to false after 1000 milliseconds
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    // Cleanup the timer
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
      <div className={`text-center ${styles.all} ${theme === 'light' ? styles.light : styles.dark}`} >
        <div className={`${styles.JoinUs} ${theme === 'light' ? styles.light : styles.dark}`}>
          <MDBContainer className={`${styles.contain} ${isRTL ? styles.containRtl : ''}`}>
            <MDBRow className={`${styles.row} ${isRTL ? styles.Row : ''}`}>
              <MDBCol md="8" lg="7" className={`${styles.content} ${isRTL ? styles.contentRtl : ''}`}>
                <h1>{t('joinUsContent1')}</h1>
                <p>{t('joinUsContent2')}</p>
              </MDBCol>
              <MDBCol md="4" lg="4" className={styles.formContainer}>
                <h3 className={styles.formHeading}>{t('joinUs')}</h3>
                <form onSubmit={handleSubmit} className={`${styles.form} ${theme === 'light' ? styles.light : styles.dark} ${isRTL ? styles.formRtl : ''}`}>
                  <div className={`${styles.dropdown} ${isRTL ? styles.dropdownRTL : ''}`}>
                    <div
                      className={`${styles.dropdownToggle} ${styles.customDropdownToggle}`}
                      onClick={togglePartnershipDropdown}
                    >
                      {getPartnershipTypeName(partnershipType) || t('partnershipType')}
                    </div>
                    {isPartnershipDropdownOpen && (
                      <div className={`${styles.dropdownMenu} ${styles.customDropdownMenu}`}>
                        {partnershipTypes.map((type) => (
                          <div
                            key={type.id}
                            onClick={() => handlePartnershipTypeSelect(type.id)}
                            className={`${styles.dropdownItem} ${isRTL ? styles.dropdownItemRTL : ''}`}
                          >
                            {i18n.language === 'ar' ? partnershipTranslations[type.id] || type.partenership_type : type.partenership_type}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  {['1', '2', '3'].includes(partnershipType.toString()) && (
                    <>
                      <MDBRow>
                        <MDBCol md="12">
                          <input
                            id="formBusinessName"
                            type="text"
                            value={businessName}
                            onChange={handleInputChange}
                            onFocus={() => setBnameError(null)}
                            className={`${styles.formControl}`}
                            placeholder={t('businessName')}
                          />
                          {BnameError && <div className={`${styles.errorMessage} ${theme === 'dark' ? styles.errorDark : ''}`}>{BnameError}</div>}
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol md="12">
                          <input
                            id="formBusinessWebsite"
                            type="url"
                            value={businessWebsite}
                            onChange={handleInputChange}
                            onFocus={() => setBwebsiteError(null)}
                            className={`${styles.formControl}`}
                            placeholder={t('businessWebsite')}
                          />
                          {BwebsiteError && <div className={`${styles.errorMessage} ${theme === 'dark' ? styles.errorDark : ''}`}>{BwebsiteError}</div>}
                        </MDBCol>
                      </MDBRow>
                    </>
                  )}

                  <div className={`${styles.dropdown} ${isRTL ? styles.dropdownRTL : ''}`}>
                    <div
                      className={`${styles.dropdownToggle} ${styles.customDropdownToggle}`}
                      onClick={toggleCountryDropdown}
                    >
                      {selectedCountryName || t('selectedCountryName')}
                    </div>
                    {isCountryDropdownOpen && (
                      <div className={`${styles.dropdownMenu} ${styles.customDropdownMenu}`}>
                        <div className={styles.searchContainer}>
                          <input
                            type="text"
                            value={countrySearchTerm}
                            onChange={handleCountrySearchChange}
                            className={`mb-0 ${styles.formControl}`}
                            style={{ height: "30px" }}
                            placeholder={t('searchCountry')}
                          />
                        </div>
                        {filteredCountries.map((countryItem) => (
                          <div
                            key={countryItem.id}
                            onClick={() => handleCountrySelect(countryItem.id, countryItem.name)}
                            className={`${styles.countryItem} ${isRTL ? styles.countryItemRTL : ''}`}
                          >
                            <img
                              src={getFlagUrl(countryItem.flag.id)}
                              alt={countryItem.name}
                              className={styles.countryFlag}
                            />
                            {i18n.language === 'ar' ? countryTranslations[countryItem.id] || countryItem.name : countryItem.name}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <MDBRow>
                    <MDBCol md="12">
                      <input
                        id="formFullName"
                        type="text"
                        value={fullName}
                        onChange={handleInputChange}
                        onFocus={() => setFullNameError(null)}
                        className={`${styles.formControl}`}
                        placeholder={t('fullName')}
                      />
                      {fullNameError && <div className={`${styles.errorMessage} ${theme === 'dark' ? styles.errorDark : ''}`}>{fullNameError}</div>}
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <input
                        id="formEmail"
                        type="email"
                        value={email}
                        onChange={handleInputChange}
                        onFocus={() => setEmailError(null)}
                        className={`${styles.formControl}`}
                        placeholder={t('emailPlaceholder')}
                      />
                      {emailError && <div className={`${styles.errorMessage} ${theme === 'dark' ? styles.errorDark : ''}`}>{emailError}</div>}
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <input
                        id="formPhone"
                        type="tel"
                        value={phone}
                        onChange={handleInputChange}
                        onFocus={() => setPhoneError(null)}
                        className={`${styles.formControl}`}
                        placeholder={t('phonePlaceholder')}
                      />
                      {phoneError && <div className={`${styles.errorMessage} ${theme === 'dark' ? styles.errorDark : ''}`}>{phoneError}</div>}
                    </MDBCol>
                  </MDBRow>

                  <button type="submit" className={styles.JoinUsBTN}>
                    {submitting ? 'Submitting...' : t('joinUs')}
                  </button>

                  {generalError && (
                    <div className={`mt-3 ${styles.alert} alert alert-danger ${styles.generalError}`}>
                      {t('fillAllInputs')}
                    </div>
                  )}
                </form>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
      </div>

)}

{alertMessage && (
  <div className={`alert alert-${alertType} ${styles.alertCenter}`}>
    {alertMessage}
  </div>
)}
</>
);
}

export default JoinUs;
