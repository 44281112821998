import React, { useEffect, useState } from 'react'
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon ,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
} from 'mdb-react-ui-kit';
import styles from './Footer.module.css'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
export default function Footer() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [centredModal, setCentredModal] = useState(false);
  const toggleOpen = () => setCentredModal(!centredModal);

    const { t, i18n } = useTranslation();
    const isRTL = i18n.language === 'ar';
  return (
    <div>
      {/* p-xl-5 */}
      <MDBFooter  className={`text-start text-lg-start text-light ${styles.Footer} ${isRTL ? styles.Frtl : ''}`} >
       
      <MDBContainer className='text-start text-md-start mt-5'>
          <MDBRow className={`mt-3 ${isRTL ? 'flex-row-reverse' : ''}`}>
          <MDBCol md="3" lg="3" xl="3" sm="6"    className={`mx-auto mb-4 ${styles['custom-cols']}`} >
            <h6 className=' mb-4'>{t('FooterContact')}</h6>
            <p className={styles.email}>
  <a href="mailto:info@riderscars.com?subject=Inquiry" className={styles.emailLink}>info@riderscars.com</a>
</p>

<p>
<Link target='_blank' to='https://wa.me/201114052852'>+20 111 405 2852
                  
                  </Link>
</p>

<p>
<Link target='_blank' to='https://www.facebook.com/people/Riders-Cars/61560225080955/'>
                    <MDBIcon size='lg' className={`me-3 ${styles['icon']}`} fab icon="facebook" />
                  </Link>

                
               
                 <Link target='_blank' to='https://www.linkedin.com/company/riders-cars/?viewAsMember=true'>
                    <MDBIcon size='lg'   className={`me-3 ${styles['icon']}`} fab icon="linkedin" />
                  </Link>
                  
                  
                  <Link target='_blank' to='https://wa.me/201114052852'>
                    <MDBIcon size='lg' fab icon="whatsapp" />
                  </Link>
                  </p>
 
          </MDBCol>

            <MDBCol md="2" lg="2" xl="2"  sm="6"  className={`mx-auto mb-4 ${styles['custom-cols']}`} >
              <h6 className='mb-4'>
              
              {t('FooterExplorate')}
              </h6>
              <p>
              <Link to="/JoinUs">
              {t('nav1')}
              </Link>

              </p>
              <p>
              <Link to="/Blog">
              {t('nav3')}
              
              </Link>

              </p>
              <p style={{cursor:"pointer"}}    onClick={toggleOpen} >
              {t('downloadApp')}
        
              </p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2"  sm="6"   className={`mx-auto mb-4 ${styles['custom-cols']}`}>
              <h6 className=' mb-4'> {t('Headquarter')}</h6>
              <p   className={` ${styles['HeadquarterPlac']}`}>
              {t('HeadquarterPlac')}
              
              </p>
           
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" sm="6"  className={`mx-auto mb-md-0 mb-4 ${styles['custom-cols']}`}  >
              <h6 className=' mb-4' >   {t('downloadApp')}</h6>
              <p>
              <img
              style={{cursor:"pointer"}} 
                  src={require('../../assets/images/Google Play.png')}
                  alt="Google Play Store"
                 width={120}
                 height={35}
                 onClick={toggleOpen}
                />
              
              </p>
              <p>
              <img
              style={{cursor:"pointer"}} 
                  src={require('../../assets/images/App Store.png')}
                  alt="Apple App Store"
                  width={120}
                  height={35}
                  className='mb-3'
                  onClick={toggleOpen}
                />
              </p>
              
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      
     
    </MDBFooter>


    <MDBModal tabIndex='-1' open={centredModal} onClose={() => setCentredModal(false)}>
        <MDBModalDialog centered style={{ height: "295px" }}>
          <MDBModalContent>
            <MDBModalHeader style={{ border: "none" }}>
              <MDBBtn className="btn-close" color="none" onClick={toggleOpen}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <p className={`${styles.popupParagraph}`}>
                {t('modal.launchingSoon')}<br />
              </p>

              <div className={`${styles.popupIconBody}`}>
                <div className={`${styles.popupIcon}`}>
                  <Link target='_blank' to='https://www.facebook.com/people/Riders-Cars/61560225080955/'>
                    <MDBIcon size='lg' className="" fab icon="facebook" />
                  </Link>
                </div>
                <div className={`${styles.popupIcon}`}>
                  <Link target='_blank' to='https://www.linkedin.com/company/riders-cars/?viewAsMember=true'>
                    <MDBIcon size='lg' fab icon="linkedin" />
                  </Link>
                </div>
                <div className={`${styles.popupIcon}`}>
                  <Link target='_blank' to='https://wa.me/201114052852'>
                    <MDBIcon size='lg' fab icon="whatsapp" />
                  </Link>
                </div>
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  )
}