
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
  MDBIcon,
} from 'mdb-react-ui-kit';
import styles from './Home.module.css';
import { Link } from 'react-router-dom';
import { useTheme } from '../../themes/ThemesContext';
  // import Navbar from '../../components/Header/Navbar';
  import Loading from '../../components/Loading/LodingPage'; // Import your loading component

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t, i18n } = useTranslation();
  const [centredModal, setCentredModal] = useState(false);
  const { theme } = useTheme();
  const [loading, setLoading] = useState(true); // Add loading state here

  const toggleOpen = () => setCentredModal(!centredModal);
  const isRTL = i18n.language === 'ar';

  useEffect(() => {
    // Set loading to false after 1000 milliseconds
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    // Cleanup the timer
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
       {loading ? (
        <Loading />
      ) : (
    <div  className={`text-center ${styles.all}  ${theme === 'light' ? styles.light : styles.dark}`} >
        

     <section className={`${styles.home}`}>
      {/* <Navbar className={`text-center ${styles.nav}  ${theme === 'light' ? styles.light : styles.dark}`}  changeLanguage={changeLanguage} /> */}

        <MDBContainer  className={`${styles.Containe} ${i18n.language === 'ar' ? styles.allRtl : ''} `}>

          <MDBRow className={`d-flex align-items-center justify-content-center`}>
            <MDBCol md="6" className={`${styles.contnet}` }>
              <h1>{t('home.title')}</h1>
              <p>{t('home.description')}</p>
              <MDBBtn className={`${styles.dwonloadBTN}`} onClick={toggleOpen}>{t('home.downloadButton')}</MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <section className={`text-center ${styles.services} ${i18n.language === 'ar' ? styles.allRtl : ''} ${theme === 'light' ? styles.light : styles.dark}`}>
  <MDBContainer>
    <MDBRow className={`d-flex align-items-center justify-content-center ${isRTL ? 'flex-row-reverse' : ''}`}>
    <h1 className={styles.whyChooseTitle}>
        {i18n.language === 'ar' ? (
          <>
           <span>    {t('home.whyChooseTitle').split(' ').map((word, index, arr) => (
              <span key={index} className={index === arr.length - 2 ? styles.whyChooseText : index === arr.length - 1 ? styles.ridersText : ''}>
                {word}{' '}
              </span>
            ))}  ؟</span>
          
          </>
        ) : (
          <>
            {t('home.whyChooseTitle').split(' ').map((word, index, arr) => (
              <span key={index} className={index === arr.length - 2 ? styles.whyChooseText : index === arr.length - 1 ? styles.ridersText : ''}>
                {word}{' '}
              </span>
            ))}
            <span>?</span>
          </>
        )}
      </h1>
      
      <img src={require('../../assets/images/icons/Vector.png')} alt="Riders underline" className={`${styles.vectorImage}`} />
      <MDBCol md="12" lg="4" sm="12" className={`${styles.serviceCol}`}>
        <div>
          <div className={`${styles.IconBody}`}>
            <img src={require('../../assets/images/icons/planet.png')} alt="tv" />
          </div>
          <h5>{t('home.service-H5-1')}</h5>
          <p>{t('home.service-p-1')}</p>
        </div>
      </MDBCol>
      <MDBCol md="12" lg="4" sm="12" className={`${styles.serviceCol}`}>
        <div>
          <div className={`${styles.IconBody}`}>
            <img src={require('../../assets/images/icons/share.png')} alt="share" />
          </div>
          <h5>{t('home.service-H5-2')}</h5>
          <p>{t('home.service-p-2')}</p>
        </div>
      </MDBCol>
      <MDBCol md="12" lg="4" sm="12" className={`${styles.serviceCol}`}>
        <div>
          <div className={`${styles.IconBody}`}>
            <img src={require('../../assets/images/icons/ghost.png')} alt="share" />
          </div>
          <h5>{t('home.service-H5-3')}</h5>
          <p>{t('home.service-p-3')}</p>
        </div>
      </MDBCol>
    </MDBRow>
  </MDBContainer>
</section>


      <section   className={`text-center ${styles.downloadSection} ${i18n.language === 'ar' ? styles.allRtl : ''} ${theme === 'light' ? styles.light : styles.dark}`}>
        <div className={`${styles.downloadContent}`}>
          <MDBRow>
            <MDBCol>
              <MDBBtn className={`${styles.dwonloadBTN}`} onClick={toggleOpen}>{t('home.downloadButton')}</MDBBtn>
              <p>{t('home.appDescription')}</p>
            </MDBCol>
          </MDBRow>
        </div>
      </section>

      <section className={`text-center ${styles.app} ${i18n.language === 'ar' ? styles.allRtl : ''}  ${theme === 'light' ? styles.light : styles.dark}`} >
        <div className={styles.appContent}>
          <MDBRow>
            <MDBCol md="12">
              <h1 className={styles.appHeading} >{t('home.appTitle')}</h1>
              <div   className={styles.storeButtons} >
          
              <img
                
                src={require('../../assets/images/Google Play.png')}
                alt="Google Play Store"
                className={styles.storeButton}
                onClick={toggleOpen}
              />
          
                

                <img
                  src={require('../../assets/images/App Store.png')}
                  alt="Apple App Store"
                  className={styles.storeButton}
                  onClick={toggleOpen}
                />
              </div>
            </MDBCol>
          </MDBRow>
        </div>
      </section>

      <MDBModal tabIndex='-1' open={centredModal} onClose={() => setCentredModal(false)}>
        <MDBModalDialog centered style={{ height: "235px" }}>
          <MDBModalContent>
            <MDBModalHeader style={{ border: "none" }}>
              <MDBBtn className="btn-close" color="none" onClick={toggleOpen}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              
            <p className={styles.popupParagraph}>
      {t('modal.launchingSoon').split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </p>

              <div className={`${styles.popupIconBody}`}>
                <div className={`${styles.popupIcon}`}>
                  <Link target='_blank' to='https://www.facebook.com/people/Riders-Cars/61560225080955/'>
                    <MDBIcon size='lg' className="" fab icon="facebook" />
                  </Link>
                </div>
                <div className={`${styles.popupIcon}`}>
                  <Link target='_blank' to='https://www.linkedin.com/company/riders-cars/?viewAsMember=true'>
                    <MDBIcon size='lg' fab icon="linkedin" />
                  </Link>
                </div>
                <div className={`${styles.popupIcon}`}>
                  <Link target='_blank' to='https://wa.me/201114052852'>
                    <MDBIcon size='lg' fab icon="whatsapp" />
                  </Link>
                </div>
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
     )}
     </>
   );
 }
export default Home;

