// // import React, { useEffect, } from 'react';
// // import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// // import Home from './Pages/Home/Home';
// // import Blog from './Pages/Blog/Blog';
// // import JoinUs from './Pages/JoinUs/JoinUs';
// // import PostDetail from './Pages/Blog/PostDetail';
// // import './i18n';
// // import './index.css';
// // import './App.css';
// // import Footer from './components/Footer/Footer';
// // import { useTranslation } from 'react-i18next';
// // import Navbar from './components/Header/Navbar';


// // function App() {
// //   const { i18n } = useTranslation();

// //   useEffect(() => {
// //     const selectedLanguage = localStorage.getItem('selectedLanguage') || 'en';
// //     if (selectedLanguage === 'ar') {
// //       document.body.classList.add('arabic-font');
// //     } else {
// //       document.body.classList.remove('arabic-font');
// //     }
// //   }, [i18n.language]);

// //   useEffect(() => {
// //     const storedLanguage = localStorage.getItem('selectedLanguage');
// //     if (storedLanguage) {
// //       i18n.changeLanguage(storedLanguage);
// //     }
// //   }, []);

// //   const changeLanguage = (lng) => {
// //     i18n.changeLanguage(lng);
// //   };

// //   return (
// //     <>
// //       <Navbar changeLanguage={changeLanguage} />
// //       <div className="App main-content">
// //         <Routes>
// //           <Route path="/" element={<Home />} />
// //           <Route path="/Home" element={<Home />} />
// //           <Route path="/Blog" element={<Blog />} />
// //           <Route path="/post/:slug" element={<PostDetail />} />
// //           <Route path="/JoinUs" element={<JoinUs />} />
     
// //         </Routes>
// //         <Footer />
// //       </div>
// //     </>
// //   );
// // }

// // export default App;


// import React, { useEffect, useState } from 'react';
// import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
// import Home from './Pages/Home/Home';
// import Blog from './Pages/Blog/Blog';
// import JoinUs from './Pages/JoinUs/JoinUs';
// import PostDetail from './Pages/Blog/PostDetail';
// import './i18n';
// import './index.css';
// import './App.css';
// import Footer from './components/Footer/Footer';
// import { useTranslation } from 'react-i18next';
// import Navbar from './components/Header/Navbar';
// import Loading from './components/Loading/LodingPage';
// import Back from './components/BackToTop/Back';
// import NotFound from './components/NotFound/NotFound';


// const App = () => {
//   const { i18n } = useTranslation();
//   const [loading, setLoading] = useState(false);
//   const location = useLocation();

//   useEffect(() => {
//     const handleRouteChange = () => {
//       setLoading(true);
//       setTimeout(() => {
//         setLoading(false);
//       }, 1500); // Show loading screen for 1.5 seconds
//     };

//     handleRouteChange();
//   }, [location]);

//   useEffect(() => {
//     const selectedLanguage = localStorage.getItem('selectedLanguage') || 'en';
//     if (selectedLanguage === 'ar') {
//       document.body.classList.add('arabic-font');
//     } else {
//       document.body.classList.remove('arabic-font');
//     }
//   }, [i18n.language]);

//   useEffect(() => {
//     const storedLanguage = localStorage.getItem('selectedLanguage');
//     if (storedLanguage) {
//       i18n.changeLanguage(storedLanguage);
//     }
//   }, []);

//   const changeLanguage = (lng) => {
//     i18n.changeLanguage(lng);
//   };

//   return (
//     <>
//       {loading && <Loading />}
//       <Navbar changeLanguage={changeLanguage} />
//       <div className="App main-content">
//         <Routes>
//           <Route path="/" element={<Home />} />
//           <Route path="/Home" element={<Home />} />
//           <Route path="/Blog" element={<Blog />} />
//           <Route path="/post/:slug" element={<PostDetail />} />
//           <Route path="/JoinUs" element={<JoinUs />} />
//           <Route path="*" element={<NotFound />} />
//         </Routes>
//         <Footer />
//         <Back />
//       </div>
     
//     </>
//   );
// };

// export default App;
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Blog from './Pages/Blog/Blog';
import JoinUs from './Pages/JoinUs/JoinUs';
import PostDetail from './Pages/Blog/PostDetail';
import './i18n';
import './index.css';
import './App.css';
import Footer from './components/Footer/Footer';
import { useTranslation } from 'react-i18next';
import Navbar from './components/Header/Navbar';
import Back from './components/BackToTop/Back';
import NotFound from './components/NotFound/NotFound';

const App = () => {
  const { i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    const selectedLanguage = localStorage.getItem('selectedLanguage') || 'en';
    if (selectedLanguage === 'ar') {
      document.body.classList.add('arabic-font');
    } else {
      document.body.classList.remove('arabic-font');
    }
  }, [i18n.language]);

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <Navbar changeLanguage={changeLanguage} />
      <div className="App main-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/post/:slug" element={<PostDetail />} />
          <Route path="/JoinUs" element={<JoinUs />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
        <Back />
      </div>
    </>
  );
};

export default App;
