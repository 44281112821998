

// import React, { useEffect, useState } from 'react';
// import styles from './Blog.module.css';
// import {
//   MDBContainer,
//   MDBRow,
//   MDBCol,
//   MDBCard,
//   MDBCardBody,
//   MDBCardImage,
//   MDBCardTitle,
//   MDBCardText,
//   MDBBadge
// } from 'mdb-react-ui-kit';
// import { useTheme } from '../../themes/ThemesContext';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';

// import { useTranslation } from 'react-i18next';

// const Blog = () => {
//   const { theme } = useTheme();
//   const { t, i18n } = useTranslation();
//   const [posts, setPosts] = useState([]);
//   const [categories, setCategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [errorMessage, setErrorMessage] = useState('');
//   const navigate = useNavigate();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//     fetchPosts(); // Fetch posts initially
//   }, []);

//   useEffect(() => {
//     fetchCategories();
//   }, []);

//   useEffect(() => {
//     fetchPosts(); // Refetch posts when language changes
//   }, [i18n.language]);

//   const handleError = (error) => {
//     // Log the error (you could send this to a logging service)
//     console.error('Error fetching data:', error);

//     // Show a user-friendly error message
//     if (error.response) {
//       // Server responded with a status other than 200 range
//       setErrorMessage(t('serverErrorMessage', { status: error.response.status }));
//     } else if (error.request) {
//       // Request was made but no response received
//       setErrorMessage(t('networkErrorMessage'));
//     } else {
//       // Something else caused the error
//       setErrorMessage(t('unexpectedErrorMessage'));
//     }
//   };


//   const fetchPosts = async () => {
//     try {
//       const response = await axios.get('https://admin.riderscars.com/items/posts');
//       const postsWithImages = await Promise.all(response.data.data.map(async post => {
//         if (post.status === "published") {
//           const imageUrl = post.image ? await fetchImageUrl(post.image) : null;
//           return { ...post, imageUrl };
//         }
//         return null;
//       }));
//       let filteredPosts = postsWithImages.filter(post => post !== null);

//       // Filter posts based on selected language
//       if (i18n.language === 'ar') {
//         filteredPosts = filteredPosts.filter(post => post.post_language === 'arabic');
//       } else {
//         filteredPosts = filteredPosts.filter(post => post.post_language === 'english');
//       }

//       setPosts(filteredPosts);
//     } catch (error) {
//       handleError(error);
//     }
//   };

//   const fetchImageUrl = async (imageId) => {
//     const response = await axios.get(`https://admin.riderscars.com/files/${imageId}`);
//     return `https://admin.riderscars.com/assets/${response.data.data.filename_disk}`;
//   };

//   const fetchCategories = async () => {
//     try {
//       const response = await axios.get('https://admin.riderscars.com/items/Post_category');
//       const categoriesData = response.data.data;

//       // Fetch translations for categories
//       const translationsResponse = await axios.get('https://admin.riderscars.com/items/Post_category_translations');
//       const translationsData = translationsResponse.data.data;

//       // Map translations to categories based on Post_category_id
//       const categoriesWithTranslations = categoriesData.map(category => {
//         const translation = translationsData.find(tr => tr.Post_category_id === category.id);
//         if (translation) {
//           return { ...category, translatedTitle: translation.title };
//         } else {
//           return { ...category, translatedTitle: category.title }; // Fallback to original title if no translation found
//         }
//       });

//       setCategories(categoriesWithTranslations);
//     } catch (error) {
//       handleError(error);
//     }
//   };

//   const filterPostsByCategory = (categoryId) => {
//     setSelectedCategory(categoryId);
//   };

//   const resetFilters = () => {
//     setSelectedCategory(null);
//     setSearchTerm('');
//   };

//   const filteredPosts = posts.filter((post) => {
//     const matchesCategory = selectedCategory ? post.category === selectedCategory : true;
//     const matchesSearch = post.title.toLowerCase().includes(searchTerm.toLowerCase()) || post.content.toLowerCase().includes(searchTerm.toLowerCase());
//     return matchesCategory && matchesSearch;
//   });

//   const shuffleArray = (array) => {
//     for (let i = array.length - 1; i > 0; i--) {
//       const j = Math.floor(Math.random() * (i + 1));
//       [array[i], array[j]] = [array[j], array[i]];
//     }
//     return array;
//   };

//   const handleImageClick = (post) => {
//     navigate(`/post/${post.slug}`, { state: { post } });
//   };

//   const randomPosts = shuffleArray([...posts]).slice(0, 3);

  

//   const isRTL = i18n.language === 'ar';

//   return (
//     <div className={` ${styles.all}  ${isRTL ? styles.rtl : ''} ${theme === 'light' ? styles.light : styles.dark}`}>
//       <div className={styles.header}>
       
//           <div className={`${styles['header-content']} ${isRTL ? styles['header-contentRtl'] : ''}`}>
//             <p>{t('headertitle1')}</p>
//             <h1>{t('headertitle2')}</h1>
//             <p className={styles['subtitle2']}>{t('headerDescription')}</p>
//           </div>
 
//       </div>

//       {errorMessage && (
//         <div className={styles.error}>
//           <p>{errorMessage}</p>
//         </div>
//       )}

//       <MDBContainer className={`text-center ${styles['popular-topics']} ${isRTL ? styles.rtl : ''} ${theme === 'light' ? styles.light : styles.dark}`}>
//         <h2>{t('POPLUR1')}</h2>
//         <div className={`text-center ${styles['category-buttons']} ${theme === 'light' ? styles.light : styles.dark}`}>
//           <p
//             className={selectedCategory === null ? styles.active : ''}
//             onClick={resetFilters}
//             style={selectedCategory === null ? { color: '#BF00C2' } : {}}
//           >
//             {t('allCategories')}
//           </p>
//           {categories.map((category) => (
//             <p
//               key={category.id}
//               className={selectedCategory === category.id ? styles.active : ''}
//               onClick={() => filterPostsByCategory(category.id)}
//               style={selectedCategory === category.id ? { color: '#BF00C2' } : {}}
//             >
//               {i18n.language === 'ar' ? category.translatedTitle : category.title}
//             </p>
//           ))}
//         </div>

//         <MDBRow className={styles.posts}>
//           {filteredPosts.length > 0 ? (
//             filteredPosts.map((post) => (
//               <MDBCol md="3" key={post.id} className="mb-4">
//                 <MDBCard className={styles['post-card']} onClick={() => handleImageClick(post)}>
//                   <div className={styles['imageContainer']}>
//                     {post.imageUrl ? (
//                       <MDBCardImage
//                         src={post.imageUrl}
//                         alt={post.title}
//                         className={styles['cardImage']}
//                       />
//                     ) : (
//                       <div className={styles['imagePlaceholder']}>
//                         No Image Available
//                       </div>
//                     )}
//                     <MDBBadge
//                       pill
//                       style={{ backgroundColor: categories.find(category => category.id === post.category)?.color }}
//                       className={styles['categoryBadge']}
//                     >
//                       {i18n.language === 'ar' ? categories.find(category => category.id === post.category)?.translatedTitle : categories.find(category => category.id === post.category)?.title}
//                     </MDBBadge>
//                   </div>

//                   <MDBCardBody
//                     className={`${styles['post-content']} ${theme === 'light' ? styles.light : styles.dark} ${i18n.language === 'ar' ? styles['font-almarai'] : '' } ${i18n.language === 'en' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''}`}>
//                     <MDBCardText className={`${styles['post-date']} ${i18n.language === 'ar' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''} ${i18n.language === 'en' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''}`}>
//                       {new Date(post.date_published).toLocaleDateString()}
//                     </MDBCardText>
//                     <MDBCardTitle className={`${styles['post-title']} ${i18n.language === 'ar' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''}  ${i18n.language === 'en' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''}`}>
//                       {post.title}
//                     </MDBCardTitle>
//                     <MDBCardText dangerouslySetInnerHTML={{ __html: post.summary }} className={` ${styles['post-summary']} ${i18n.language === 'ar' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''} ${i18n.language === 'en' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''}`}>
//                     </MDBCardText>
//                   </MDBCardBody>
//                 </MDBCard>
//               </MDBCol>
//             ))
//           ) : (
//                     <p className={`${styles['No-Posts']} ${theme === 'light' ? styles.light : styles.dark}`}>{t('noPostsMessage')}</p>

//           )}
//         </MDBRow>
//       </MDBContainer>

//       <div className={styles.panner}>
//         <MDBContainer>
//           <div className={styles['panner-content']}>
//             <p>{t('pannertitle1')}</p>
//             <h1>{t('pannertitle2')}</h1>

//             {/* <p  className={styles['subtitl2']}>{t('pannerDescription')}</p> */}
//           </div>
//         </MDBContainer>
//       </div>

//       <MDBContainer className={`text-center ${styles['popular-topics']} ${isRTL ? styles.rtl : ''} ${theme === 'light' ? styles.light : styles.dark}`}>
//         <h2>{t('POPLUR')}</h2>
//         <div className={styles['second-posts-container']}>
//           {randomPosts.length > 0 ? (
//             randomPosts.map((post) => (
//               <MDBCol md="3" key={post.id} className="mx-4">
//                 <div className={`${styles['second-post-card']} ${isRTL ? styles.rtlSecond : ''}`} onClick={() => handleImageClick(post)}>
//                   <div className={styles['imageContainer2']}>
//                     {post.imageUrl ? (
//                       <MDBCardImage
//                         src={post.imageUrl}
//                         alt={post.title}
//                         className={styles['cardImage2']}
//                       />
//                     ) : (
//                       <div className={styles['imagePlaceholder']}>
//                         No Image Available
//                       </div>
//                     )}
//                     <MDBBadge
//                       pill
//                       style={{ backgroundColor: categories.find(category => category.id === post.category)?.color }}
//                       className={styles['categoryBadge2']}
//                     >
//                       {i18n.language === 'ar' ? categories.find(category => category.id === post.category)?.translatedTitle : categories.find(category => category.id === post.category)?.title}
//                     </MDBBadge>
//                   </div>
//                   <div className={`${styles['second-post-content']} ${theme === 'light' ? styles.light : styles.dark}`}>
//                     <p className={`${styles['post-date']} ${i18n.language === 'ar' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''} ${i18n.language === 'en' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''}`}>{new Date(post.date_published).toLocaleDateString()}</p>
//                     <h3 className={`${styles['post-title']} ${i18n.language === 'ar' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''}  ${i18n.language === 'en' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''}`}>{post.title}</h3>
//                     {/* <p className={styles['post-summary']} dangerouslySetInnerHTML={{ __html: post.summary }}></p> */}
//                   </div>
//                 </div>
//               </MDBCol>
//             ))
//           ) : (
//             <p className={`${styles['No-Posts']} ${theme === 'light' ? styles.light : styles.dark}`}>{t('noPostsMessage')}</p>
//           )}
//         </div>
//       </MDBContainer>
//     </div>
//   );
// };

// export default Blog;


import React, { useEffect, useState } from 'react';
import styles from './Blog.module.css';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
  MDBBadge
} from 'mdb-react-ui-kit';
import { useTheme } from '../../themes/ThemesContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Loading from '../../components/Loading/LodingPage';

const Blog = () => {
  const { theme } = useTheme();
  const { t, i18n } = useTranslation();
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true); // Add loading state here
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchPosts(); // Fetch posts initially
  }, []);

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    fetchPosts(); // Refetch posts when language changes
  }, [i18n.language]);

  const handleError = (error) => {
    console.error('Error fetching data:', error);

    if (error.response) {
      setErrorMessage(t('serverErrorMessage', { status: error.response.status }));
    } else if (error.request) {
      setErrorMessage(t('networkErrorMessage'));
    } else {
      setErrorMessage(t('unexpectedErrorMessage'));
    }
  };

  const fetchPosts = async () => {
    try {
      const response = await axios.get('https://admin.riderscars.com/items/posts');
      const postsWithImages = await Promise.all(response.data.data.map(async post => {
        if (post.status === "published") {
          const imageUrl = post.image ? await fetchImageUrl(post.image) : null;
          return { ...post, imageUrl };
        }
        return null;
      }));
      let filteredPosts = postsWithImages.filter(post => post !== null);

      if (i18n.language === 'ar') {
        filteredPosts = filteredPosts.filter(post => post.post_language === 'arabic');
      } else {
        filteredPosts = filteredPosts.filter(post => post.post_language === 'english');
      }

      setPosts(filteredPosts);
      setLoading(false); // Set loading to false after fetching posts
    } catch (error) {
      handleError(error);
      setLoading(false); // Set loading to false even if there's an error
    }
  };

  const fetchImageUrl = async (imageId) => {
    const response = await axios.get(`https://admin.riderscars.com/files/${imageId}`);
    return `https://admin.riderscars.com/assets/${response.data.data.filename_disk}`;
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get('https://admin.riderscars.com/items/Post_category');
      const categoriesData = response.data.data;

      const translationsResponse = await axios.get('https://admin.riderscars.com/items/Post_category_translations');
      const translationsData = translationsResponse.data.data;

      const categoriesWithTranslations = categoriesData.map(category => {
        const translation = translationsData.find(tr => tr.Post_category_id === category.id);
        if (translation) {
          return { ...category, translatedTitle: translation.title };
        } else {
          return { ...category, translatedTitle: category.title };
        }
      });

      setCategories(categoriesWithTranslations);
    } catch (error) {
      handleError(error);
    }
  };

  const filterPostsByCategory = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  const resetFilters = () => {
    setSelectedCategory(null);
    setSearchTerm('');
  };

  const filteredPosts = posts.filter((post) => {
    const matchesCategory = selectedCategory ? post.category === selectedCategory : true;
    const matchesSearch = post.title.toLowerCase().includes(searchTerm.toLowerCase()) || post.content.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesCategory && matchesSearch;
  });

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const handleImageClick = (post) => {
    navigate(`/post/${post.slug}`, { state: { post } });
  };

  const randomPosts = shuffleArray([...posts]).slice(0, 3);

  const isRTL = i18n.language === 'ar';

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className={` ${styles.all}  ${isRTL ? styles.rtl : ''} ${theme === 'light' ? styles.light : styles.dark}`}>
          <div className={styles.header}>
            <div className={`${styles['header-content']} ${isRTL ? styles['header-contentRtl'] : ''}`}>
              <p>{t('headertitle1')}</p>
              <h1>{t('headertitle2')}</h1>
              <p className={styles['subtitle2']}>{t('headerDescription')}</p>
            </div>
          </div>

          {errorMessage && (
            <div className={styles.error}>
              <p>{errorMessage}</p>
            </div>
          )}

          <MDBContainer className={`text-center ${styles['popular-topics']} ${isRTL ? styles.rtl : ''} ${theme === 'light' ? styles.light : styles.dark}`}>
            <h2>{t('POPLUR1')}</h2>
            <div className={`text-center ${styles['category-buttons']} ${theme === 'light' ? styles.light : styles.dark}`}>
              <p
                className={selectedCategory === null ? styles.active : ''}
                onClick={resetFilters}
                style={selectedCategory === null ? { color: '#BF00C2' } : {}}
              >
                {t('allCategories')}
              </p>
              {categories.map((category) => (
                <p
                  key={category.id}
                  className={selectedCategory === category.id ? styles.active : ''}
                  onClick={() => filterPostsByCategory(category.id)}
                  style={selectedCategory === category.id ? { color: '#BF00C2' } : {}}
                >
                  {i18n.language === 'ar' ? category.translatedTitle : category.title}
                </p>
              ))}
            </div>

            <MDBRow className={styles.posts}>
              {filteredPosts.map((post) => (
                <MDBCol md="3" key={post.id} className="mb-4">
                  <MDBCard className={styles['post-card']} onClick={() => handleImageClick(post)}>
                    <div className={styles['imageContainer']}>
                      {post.imageUrl ? (
                        <MDBCardImage
                          src={post.imageUrl}
                          alt={post.title}
                          className={styles['cardImage']}
                        />
                      ) : (
                        <div className={styles['imagePlaceholder']}>
                          No Image Available
                        </div>
                      )}
                      <MDBBadge
                        pill
                        style={{ backgroundColor: categories.find(category => category.id === post.category)?.color }}
                        className={styles['categoryBadge']}
                      >
                        {i18n.language === 'ar' ? categories.find(category => category.id === post.category)?.translatedTitle : categories.find(category => category.id === post.category)?.title}
                      </MDBBadge>
                    </div>

                    <MDBCardBody
                      className={`${styles['post-content']} ${theme === 'light' ? styles.light : styles.dark} ${i18n.language === 'ar' ? styles['font-almarai'] : ''} ${i18n.language === 'en' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''}`}>
                      <MDBCardText className={`${styles['post-date']} ${i18n.language === 'ar' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''} ${i18n.language === 'en' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''}`}>
                        {new Date(post.date_published).toLocaleDateString()}
                      </MDBCardText>
                      <MDBCardTitle className={`${styles['post-title']} ${i18n.language === 'ar' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''}  ${i18n.language === 'en' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''}`}>
                        {post.title}
                      </MDBCardTitle>
                      <MDBCardText dangerouslySetInnerHTML={{ __html: post.summary }} className={` ${styles['post-summary']} ${i18n.language === 'ar' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''} ${i18n.language === 'en' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''}`}>
                      </MDBCardText>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              ))}
            </MDBRow>
          </MDBContainer>

          <div className={styles.panner}>
            <MDBContainer>
              <div className={styles['panner-content']}>
                <p>{t('pannertitle1')}</p>
                <h1>{t('pannertitle2')}</h1>
              </div>
            </MDBContainer>
          </div>

          <MDBContainer className={`text-center ${styles['popular-topics']} ${isRTL ? styles.rtl : ''} ${theme === 'light' ? styles.light : styles.dark}`}>
            <h2>{t('POPLUR')}</h2>
            <div className={styles['second-posts-container']}>
              {randomPosts.map((post) => (
                <MDBCol md="3" key={post.id} className="mx-4">
                  <div className={`${styles['second-post-card']} ${isRTL ? styles.rtlSecond : ''}`} onClick={() => handleImageClick(post)}>
                    <div className={styles['imageContainer2']}>
                      {post.imageUrl ? (
                        <MDBCardImage
                          src={post.imageUrl}
                          alt={post.title}
                          className={styles['cardImage2']}
                        />
                      ) : (
                        <div className={styles['imagePlaceholder']}>
                          No Image Available
                        </div>
                      )}
                      <MDBBadge
                        pill
                        style={{ backgroundColor: categories.find(category => category.id === post.category)?.color }}
                        className={styles['categoryBadge2']}
                      >
                        {i18n.language === 'ar' ? categories.find(category => category.id === post.category)?.translatedTitle : categories.find(category => category.id === post.category)?.title}
                      </MDBBadge>
                    </div>
                    <div className={`${styles['second-post-content']} ${theme === 'light' ? styles.light : styles.dark}`}>
                      <p className={`${styles['post-date']} ${i18n.language === 'ar' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''} ${i18n.language === 'en' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''}`}>{new Date(post.date_published).toLocaleDateString()}</p>
                      <h3 className={`${styles['post-title']} ${i18n.language === 'ar' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''}  ${i18n.language === 'en' && post.title.match(/[\u0600-\u06FF]/) ? styles.rtlfordate : ''}`}>{post.title}</h3>
                    </div>
                  </div>
                </MDBCol>
              ))}
            </div>
          </MDBContainer>
        </div>
      )}
    </>
  );
};

export default Blog;
